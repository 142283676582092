import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { DineInDetails } from './DineInDetails';
import { getDineInInfo } from '../../../../core/logic/dispatchLogic';
import { selectOpenLocations } from '../../../../state/selectors/locationsSelector';

function mapStateToProps(state: AppState, ownProps: any) {
  const locations = selectOpenLocations(state);
  const lockedDineInLocation = state.session.lockedDineInLocation;
  const { selectedLocation } = ownProps;
  const selectedLocationFull = locations?.find((location) => location.currentLocationId === selectedLocation);
  const dineInInfo = getDineInInfo(
    selectedLocationFull ||
      state.session.locations.find((loc) => loc.currentLocationId === lockedDineInLocation) ||
      state.session.restaurant,
  );
  return {
    ...ownProps,
    description: dineInInfo ? dineInInfo.instructions : '',
    label: dineInInfo ? dineInInfo.label : '',
    address: state.session.restaurant.address.formatted,
    isMultiLocation: state.session.isMultiLocation,
    locations,
    lockedDineInLocation,
  };
}

export default connect(mapStateToProps)(DineInDetails);
