import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import ThankYou from './ThankYou';
import { bindActionCreators, Dispatch } from 'redux';
import { initCheckout, loginMember } from '../../../../state/checkout/checkout.actions';
import { initAddressForm } from '../../../../state/addressInformationForm/addressForm.actions';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { loyaltyProgramSelector } from '../../../../state/selectors/loyaltyProgramSelector';
import { goToLoyaltyPage } from '../../../../state/session/session.actions';

function mapStateToProps(state: AppState) {
  const { priceComponents } = cartSummarySelector(state);
  const {
    pointsRequired,
    currencyValue,
    customPointsName = '',
    loyaltyProgramName,
    loyaltyRewardId = '',
    isLoyaltyEarnActive,
  } = loyaltyProgramSelector(state);

  return {
    order: state.checkout.orderResponse!,
    locale: state.session.restaurant.locale,
    currency: state.session.restaurant.currency,
    timezone: state.session.restaurant.timezone,
    restaurant: state.session.restaurant,
    centsAmount: priceComponents.subtotal,
    pointsRequired,
    currencyValue,
    customPointsName,
    isUserLoggedIn: state.session.isUserLoggedIn,
    loyaltyProgramName,
    loyaltyRewardId,
    isLoyaltyEarnActive,
    isRewardInstalled: state.session.isRewardInstalled,
    locations: state.session.locations,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      initCheckout,
      initAddressForm,
      loginMember,
      goToLoyaltyPage,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
